/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';
import {
    LaboratoryCategoryEnum,
    LaboratoryCategoryEnumFromJSON,
    LaboratoryCategoryEnumFromJSONTyped,
    LaboratoryCategoryEnumToJSON,
} from './LaboratoryCategoryEnum';
import {
    LaboratoryTypeEnum,
    LaboratoryTypeEnumFromJSON,
    LaboratoryTypeEnumFromJSONTyped,
    LaboratoryTypeEnumToJSON,
} from './LaboratoryTypeEnum';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface LaboratoryDTO
 */
export interface LaboratoryDTO {
    /**
     * 
     * @type {string}
     * @memberof LaboratoryDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryDTO
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryDTO
     */
    area?: number;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryDTO
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryDTO
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryDTO
     */
    name?: string | null;
    /**
     * 
     * @type {LaboratoryCategoryEnum}
     * @memberof LaboratoryDTO
     */
    category?: LaboratoryCategoryEnum;
    /**
     * 
     * @type {LaboratoryTypeEnum}
     * @memberof LaboratoryDTO
     */
    type?: LaboratoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryDTO
     */
    facultyId?: string;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryDTO
     */
    resources?: string | null;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof LaboratoryDTO
     */
    subjects?: Array<Subject> | null;
    /**
     * 
     * @type {Faculty}
     * @memberof LaboratoryDTO
     */
    faculty?: Faculty;
}

export function LaboratoryDTOFromJSON(json: any): LaboratoryDTO {
    return LaboratoryDTOFromJSONTyped(json, false);
}

export function LaboratoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaboratoryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'category': !exists(json, 'category') ? undefined : LaboratoryCategoryEnumFromJSON(json['category']),
        'type': !exists(json, 'type') ? undefined : LaboratoryTypeEnumFromJSON(json['type']),
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(SubjectFromJSON)),
        'faculty': !exists(json, 'faculty') ? undefined : FacultyFromJSON(json['faculty']),
    };
}

export function LaboratoryDTOToJSON(value?: LaboratoryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'area': value.area,
        'volume': value.volume,
        'capacity': value.capacity,
        'name': value.name,
        'category': LaboratoryCategoryEnumToJSON(value.category),
        'type': LaboratoryTypeEnumToJSON(value.type),
        'facultyId': value.facultyId,
        'description': value.description,
        'resources': value.resources,
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(SubjectToJSON)),
        'faculty': FacultyToJSON(value.faculty),
    };
}

